import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { TableOptions } from "@/api/request";
import {
  DeleteRequest,
  SearchRequest,
  SortRequest
} from "@/api/coupon/request";
import { GetRequest as GetPreviewRequest } from "@/api/coupon-preview/request";
import CouponSearch, { ListItem } from "@/store/coupon/search";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import CouponModify from "@/store/coupon/modify";
import CouponBgImageGet from "@/store/coupon-bg-image/get";
import CouponBgImageModify from "@/store/coupon-bg-image/modify";
import CouponPreviewGet from "@/store/coupon-preview/get";
import Sortable, { MoveEvent } from "sortablejs";
import { UlFileRegisiterTabType } from "@/utils/enums";
import Loading from "@/store/loading";
import { UploadRequest } from "@/api/coupon-bg-image/request";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker,
    UIDialogDelete,
    UlFileRegister
  }
})
export default class List extends Vue {
  @Prop({ required: true })
  shopId!: number;

  shopName = "";

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "クーポン";
  headingSub = "Coupon";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "クーポン", disabled: true },
    { text: "店舗一覧", to: { name: "coupon" } },
    { text: "クーポン一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "クーポンタイトル", value: "title", sortable: false },
    { text: "クーポンタイプ", value: "typeName", sortable: false },
    { text: "クーポン有効期間", value: "validityPeriod", sortable: false },
    { text: "状態", value: "publishStatus", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "プレビュー",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  initImageUrl = null as string | null;
  imageEditFlg = 0 as 0 | 1;
  image = null as string | null;
  imageId = null as number | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // 背景画像の登録・編集ダイアログの表示有無
  showBackgroundDialog = false;

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {};

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return CouponSearch.getWriteFlg;
  }

  get deleteFlg() {
    return CouponSearch.getDeleteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return CouponSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return CouponSearch.getTotalCount;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    // 店舗名を表示
    if (this.$route.query.shopName) {
      this.shopName = String(this.$route.query.shopName);
    }
    await CouponSearch.restore();

    await this.search();
    //NEW_DEV-1527 Cyber start
    //更新権限がある場合、UIDataTableをソート可能にする
    if(!this.writeFlg){
      return
    }
    this.$nextTick(() => {
      const _self = this;

      const mainTable = document.querySelector(
        ".v-data-table tbody"
      ) as HTMLElement;
      Sortable.create(mainTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.tableItems.splice(oldIndex as number, 1)[0];
          _self.tableItems.splice(newIndex as number, 0, rowSelected);
          _self.sort().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });
    });
  }

  /**
   * mountedライフサイクルフック（UIDataTableをソート可能にする）
   */
  mounted() {    
  }
  //NEW_DEV-1527 Cyber end
  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await CouponSearch.clearResponse();
    await CouponModify.clearResponse();
    await CouponBgImageGet.clearResponse();
    await CouponBgImageModify.clearResponse();
    await CouponPreviewGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: ListItem) {
    await CouponPreviewGet.get({ id: item.id } as GetPreviewRequest);
    if (CouponPreviewGet.isSuccess) {
      WindowOpen.preview(CouponPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: CouponPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "coupon-edit",
      params: { id: item.id.toString() },
      query: { shopId: this.shopId.toString(), shopName: this.shopName }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingId = item.id;
    this.deletingTitle = item.title;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    await CouponModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (CouponModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "クーポンを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: CouponModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async goToNew() {
    await this.$router.push({
      name: "coupon-new",
      query: { shopId: this.shopId.toString(), shopName: this.shopName }
    });
  }

  async onClickBackgroundDialog() {
    await CouponBgImageGet.get({ id: this.shopId });
    if (!CouponBgImageGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponBgImageGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    this.showBackgroundDialog = true;
    this.$nextTick(() => {
      this.initImageUrl = CouponBgImageGet.getImage;
    });
  }

  setFile(data: string, id: number | null) {
    this.imageEditFlg = 1;
    this.image = data;
    this.imageId = id;
  }

  unsetFile() {
    this.imageEditFlg = 1;
    this.image = null;
    this.imageId = null;
    this.initImageUrl = null;
  }

  async onClickBgImageSave() {
    this.showBackgroundDialog = false;
    await this.callUpload();
    if (CouponBgImageModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "クーポン背景画像を登録しました",
        consumePath: ""
      } as SuccessAlert);
    }
  }

  async onClickBgImageDelete() {
    this.unsetFile();
    this.showBackgroundDialog = false;
    await this.callUpload();
    if (CouponBgImageModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "クーポン背景画像を削除しました",
        consumePath: ""
      } as SuccessAlert);
    }
  }

  private async callUpload() {
    const request: UploadRequest = {
      id: this.shopId,
      imageEditFlg: this.imageEditFlg,
      image: this.image,
      imageId: this.imageId
    };
    if (this.imageEditFlg == 0 || this.imageId != null) {
      delete request.image;
    }
    await CouponBgImageModify.upload(request);
    if (!CouponBgImageModify.isSuccess) {
      await Flash.setErrorNow({
        message: CouponBgImageModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * 検索処理
   */
  async search() {
    let request = this.createRequest();
    await CouponSearch.search(request);
    if (!CouponSearch.isSuccess) {
      await Flash.setErrorNow({
        message: CouponSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * 並び替え処理
   */
  async sort() {
    if (this.loading) {
      return;
    }
    await CouponModify.sort(this.createSortRequest());
    if (!CouponModify.isSuccess) {
      await Flash.setErrorNow({
        message: CouponModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = {} as SearchRequest;
    request.shopId = this.shopId;

    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortRequest;
    request.ids = this.tableItems.map(i => i.id);
    return request;
  }
}
